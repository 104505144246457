<template>
<v-container id="data-tables" tag="section">
  <base-material-card color="indigo" icon="mdi-folder-outline" inline class="px-5 py-3">
    <template v-slot:after-heading>
      <div class="display-2 font-weight-light">
        Manage Resources
      </div>
    </template>
    <v-data-table :headers="headers" :items="resources" :disable-pagination="true" hide-default-footer item-key="uniqueID" sort-desc :loading="loading">
      <template v-slot:top>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field v-model="searchTerm" append-icon="mdi-magnify" class="ml-auto" label="Search..." hide-details single-line clearable @change="filterTable" />
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-select class="mx-auto" v-model="searchCat" :items="categoryOptions" item-text="tagName" item-value="id" label="Select a category." clearable v-on:change="filterTable"></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-select :items="pageOptions" v-model="rowsPerPage" append-icon="mdi-chevron-down" class="ml-auto" label="Rows per Page" hide-details v-on:change="filterTable">
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" md="2" class="text-sm-center">
            <v-btn v-if="reportToggle === false" color="info" class="mt-4 mr-0" @click="toggleReport">
              90 Day Report
            </v-btn>
            <v-btn v-if="reportToggle === true" color="success" class="mt-4 mr-0" @click="toggleReport">
              90 Day Report
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" md="2" class="text-sm-center">
            <v-dialog v-model="addResource" :retain-focus="false" max-width="600px" scrollable>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on" class="mt-4 mr-0">
                  Add Resource
                </v-btn>
              </template>
              <v-card :loading="cardLoading">
                <v-card-title>
                  <span class="headline">Add Resource</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field v-model="resourceName" label="Resource Title*" :rules="resourceNameRules" required></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" v-on:click.stop="addResource = false">Nevermind</v-btn>
                  <v-btn :disabled="!resourceName" color="green darken-1" v-on:click.stop="createResource">Create</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip v-if="item.published === null" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2" @click.stop="publishResourceConfirm(item)" v-bind="attrs" v-on="on" color="success">
              mdi-arrow-up-bold-circle
            </v-icon>
          </template>
          <span>Publish</span>
        </v-tooltip>
        <v-tooltip v-if="item.published !== null" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2" @click.stop="unpublishResourceConfirm(item)" v-bind="attrs" v-on="on" color="error">
              mdi-arrow-down-bold-circle
            </v-icon>
          </template>
          <span>Unpublish</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2" @click.stop="editResource(item)" v-bind="attrs" v-on="on" color="success">
              mdi-pencil
            </v-icon>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small @click.stop="deleteConfirm(item)" color="error" v-bind="attrs" v-on="on">
              mdi-delete
            </v-icon>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <h2>Sorry, we couldn't find anything that matched that search.</h2>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-btn small rounded :disabled="(prevCursor === undefined || prevCursor === '') || loading === true" color="blue darken-1" v-on:click="previousPage()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn small rounded :disabled="(nextCursor === undefined || nextCursor === '') || loading === true" color="blue darken-1" v-on:click="nextPage()">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
    <v-dialog :retain-focus="false" v-model="deleteConfirmDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Are you sure?</v-card-title>
        <v-card-text>
          This resource will be deleted, and unpublished. <br> It can be recovered later by contacting a Frog Administrator
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" v-on:click.stop="deleteConfirmDialog = false">
            No, don't delete
          </v-btn>
          <v-btn color="red darken-1" v-on:click.stop="deleteItem">
            Yes, delete resource
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :retain-focus="false" v-model="publishConfirmDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Are you sure?</v-card-title>
        <v-card-text>
          This resource will be published, and available immediately on public sites. <br> It can be unpublished or deleted at any point.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" v-on:click.stop="publishConfirmDialog = false">
            No, don't publish
          </v-btn>
          <v-btn color="green darken-1" v-on:click.stop="publishResource">
            Yes, publish resource
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :retain-focus="false" v-model="unpublishConfirmDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Are you sure?</v-card-title>
        <v-card-text>
          This resource will be unpublished, and immediately removed from public sites. <br> It can be re - published or deleted at any point.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" v-on:click.stop="unpublishConfirmDialog = false">
            No, don't unpublish
          </v-btn>
          <v-btn color="red darken-1" v-on:click.stop="unpublishResource">
            Yes, unpublish resource
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :retain-focus="false" v-model="editDialog" max-width="800">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Resource</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-text-field v-model="editResourceObject.name" label="Resource Title*" :rules="resourceNameRules" required></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-switch inset v-model="editResourceObject.publishStatus" :label="`Published: ${editResourceObject.publishStatus.toString()}`"></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select class="mx-auto" v-model="editResourceObject.resourceType" :items="resourceOptions" item-text="name" item-value="id" label="Select a Resource Type." outlined></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea outlined :rules="summaryRules" max-length="300" :counter="300" v-model="editResourceObject.summary" label="Summary"></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <h3>Categories and Tags</h3>
          <v-row>
            <v-col cols="12" md="6">
              <v-select class="mx-auto" v-model="editResourceObject.category" :items="categoryOptions" item-text="tagName" item-value="id" label="Select a category." outlined></v-select>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-autocomplete v-model="editResourceObject.superTag" :items="superTagOptions" :loading="isSuperLoading" :search-input.sync="searchSuperTag" outlined chips small-chips deletable-chips item-text="tagName" item-value="id" hide-no-data label="Available Super Tags" placeholder="Start typing to Search" append-icon="mdi-database-search" v-on:input="searchSuperTag = null">
                <template v-slot:selection="data">
                  <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select" @click:close="removeSuperTag(data.item)">
                    {{ data.item.tagName }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.tagName"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-autocomplete v-model="editResourceObject.tags" :items="tagOptions" :loading="isLoading" :search-input.sync="searchTags" outlined chips small-chips deletable-chips item-text="tagName" item-value="id" hide-no-data multiple label="Available Tags" placeholder="Start typing to Search" return-object append-icon="mdi-database-search" v-on:imput="searchTags = null">
                <template v-slot:selection="data">
                  <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select" @click:close="removeTag(data.item)">
                    {{ data.item.tagName }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.tagName"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-select class="mx-auto" v-model="editResourceObject.displayTag" :items="editResourceObject.tags" item-text="tagName" item-value="id" label="Select a display tag" outlined clearable no-data-text="Select resource tags first"></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <h3>Resource Options</h3>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-select v-model="editResourceObject.linkType" :items="linkType" item-text="name" item-value="id" label="Link Type"></v-select>
            </v-col>
            <v-col cols="12" md="6" sm="6" v-if="editResourceObject.linkType === 2">
              <v-radio-group row v-model="radioBtn">
                <v-radio label="Upload" value="upload"></v-radio>
                <v-radio label="External Link" value="link"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" v-if="radioBtn === 'link' && editResourceObject.linkType === 2">
              <v-text-field v-model="editResourceObject.link" label="Resource Link"></v-text-field>
            </v-col>
            <v-col cols="12" v-if="radioBtn === 'upload' && editResourceObject.linkType === 2">
              <fileUpload @imageUploaded="emitFile" :obj="file" fieldName="thumbnail" :directory="fileBucket" fileType="all" label="Select a File" />
            </v-col>
            <v-col cols="12" v-if="editResourceObject.linkType === 1">
              <v-text-field v-model="editResourceObject.link" label="Resource Link"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <h3>Resource Image</h3>
          <v-row>
            <v-col v-if="editResourceObject.imageUrl" cols="12">
              <v-img :src="editResourceObject.imageUrl" width="368" height="150" contain></v-img>
            </v-col>
            <v-col cols="12">
              <fileUpload @imageUploaded="emitImage" :obj="image" fieldName="thumbnail" fileType="image/png, image/jpeg" :directory="imageBucket" label="Select a Image" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" v-on:click.stop="editDialog = false">Cancel</v-btn>
          <v-btn color="green darken-1" v-on:click.stop="updateResource()">Update Resource</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snack" top right :timeout="snackTimeout" :color="snackColor">
      <v-progress-circular v-if="snackColor === 'info'" color="white" indeterminate></v-progress-circular>
      {{ snackText }}
    </v-snackbar>
  </base-material-card>
</v-container>
</template>

<script>
import resourceManagement from '@/Services/Resources/ResourceManagement'
import createResource from '@/Services/Resources/CreateResource'
import updateResource from '@/Services/Resources/UpdateResource'
import deleteResource from '@/Services/Resources/DeleteResource'
import getResource from '@/Services/Resources/ResourceDetails'
import publishResource from '@/Services/Resources/PublishResource'
import fileUpload from '@/views/dashboard/component/fileUpload'
export default {
  components: {
    fileUpload
  },
  data: () => ({
    imageBucket: process.env.VUE_APP_AWSIMAGEBUCKETRESOURCES,
    fileBucket: process.env.VUE_APP_AWSFILEBUCKETRESOURCES,
    radioBtn: null,
    reportToggle: false,
    publishResourceID: null,
    unpublishResourceID: null,
    publishConfirmDialog: false,
    unpublishConfirmDialog: false,
    snack: false,
    snackColor: '',
    snackText: '',
    snackTimeout: null,
    searchURL: null,
    nextCursor: null,
    prevCursor: null,
    rowsPerPage: 10,
    pageOptions: [5, 10, 20, 50, 100],
    resourceOptions: [{
      text: 'Select A Resource Type',
      value: null
    }],
    categoryOptions: [{
      text: 'Please Select',
      value: null
    }],
    linkType: [{
      text: 'Select A Resource Type',
      value: null
    }],
    confirmDialog: false,
    deleteConfirmDialog: false,
    loading: true,
    cardLoading: false,
    headers: [{
      text: 'ID',
      align: 'start',
      value: 'uniqueID'
    },
    {
      text: 'Name',
      value: 'name'
    },
    {
      text: 'Category',
      value: 'category'
    },
    {
      text: 'Published Date',
      value: 'publishedDate'
    },
    {
      text: 'Last Edited',
      value: 'lastEditedDate'
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false
    }
    ],
    resources: [],
    resourceName: '',
    resourceNameRules: [
      v => !!v || 'Name is required'
    ],
    summaryRules: [
      v => (v && v.length <= 300) || 'Summary must be less than 300 characters'
    ],
    errors: [],
    searchTerm: null,
    searchCat: null,
    addResource: false,
    editDialog: false,
    editResourceObject: {
      name: '',
      summary: '',
      resourceType: '',
      linkType: '',
      link: '',
      imageUrl: '',
      displayTag: '',
      category: '',
      superTag: '',
      tags: [],
      publishStatus: ''
    },
    isLoading: false,
    isSuperLoading: false,
    tagOptions: [],
    searchTags: '',
    superTagOptions: [],
    searchSuperTag: '',
    image: Object,
    file: Object
  }),
  created () {
    this.initialize()
  },
  watch: {
    async searchTags (val) {
      // Items have already been loaded
      if (this.tagOptions.length > 0) return
      // Items have already been requested
      if (this.isLoading) return
      this.isLoading = true
      // Lazily load input items
      await resourceManagement.getTags()
        .then((res) => {
          if (res.status === 200) {
            this.tagOptions = res.data
            this.isLoading = false
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to load tags. Error: ' + res.data.title
            this.snackTimeout = 5000
          }
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.error(err)
        })
    },
    async searchSuperTag (val) {
      // Items have already been loaded
      if (this.superTagOptions.length > 0) return
      // Items have already been requested
      if (this.isSuperLoading) return
      this.isSuperLoading = true
      // Lazily load input items
      await resourceManagement.getSuperTags()
        .then((res) => {
          if (res.status === 200) {
            this.superTagOptions = res.data
            this.isSuperLoading = false
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to load super tags. Error: ' + res.data.title
            this.snackTimeout = 5000
          }
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.error(err)
        })
    }
  },
  methods: {
    emitImage (e) {
      this.editResourceObject.imageUrl = e
    },
    emitFile (e) {
      this.editResourceObject.link = e
    },
    async initialize () {
      this.loading = true
      this.searchURL = null
      await resourceManagement.init(this.rowsPerPage, this.reportToggle)
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'resources', response.data.results)
            this.$set(this, 'nextCursor', response.data.nextCursor)
            this.$set(this, 'prevCursor', response.data.previousCursor)
            this.loading = false
          } else {
            this.resources = []
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Could not retrieve results'
            this.snackTimeout = 5000
            this.loading = false
          }
        })
        .catch(e => {
          this.errors.push(e)
        })
      await resourceManagement.getCat()
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'categoryOptions', response.data)
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to load categories.'
            this.snackTimeout = 5000
          }
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e)
        })
      await resourceManagement.getResourceType()
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'resourceOptions', response.data)
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to load Resource.'
            this.snackTimeout = 5000
          }
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e)
        })
      await resourceManagement.getResourceLinkType()
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'linkType', response.data)
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to load Resource.'
            this.snackTimeout = 5000
          }
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e)
        })
      this.searchTerm = null
      this.searchCat = null
    },
    async previousPage () {
      this.loading = true
      await resourceManagement.getPreviousPage(this.rowsPerPage, this.searchURL, this.prevCursor, this.reportToggle)
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'resources', response.data.results)
            this.$set(this, 'nextCursor', response.data.nextCursor)
            this.$set(this, 'prevCursor', response.data.previousCursor)
            this.loading = false
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to get previous results'
            this.snackTimeout = 5000
          }
        })
        .catch(e => {
          this.errors.push(e)
          this.loading = false
        })
    },
    async nextPage () {
      this.loading = true
      await resourceManagement.getNextPage(this.rowsPerPage, this.searchURL, this.nextCursor, this.reportToggle)
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'resources', response.data.results)
            this.$set(this, 'nextCursor', response.data.nextCursor)
            this.$set(this, 'prevCursor', response.data.previousCursor)
            this.loading = false
          } else {
            this.loading = false
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to get next results'
            this.snackTimeout = 5000
          }
        })
        .catch(e => {
          this.errors.push(e)
          this.loading = false
        })
    },
    async createResource () {
      this.cardLoading = true
      await createResource.createResource(this.resourceName)
        .then((response) => {
          if (response.status === 200) {
            this.cardLoading = false
            this.addResource = false
            this.editResource(response.data)
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to create resource'
            this.snackTimeout = 5000
          }
        })
    },
    async editResource (item) {
      await getResource.getDetails(item.uniqueID)
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'editResourceObject', response.data)
            this.editResourceObject.id = item.uniqueID
            this.editDialog = true
          } else if (response.status === 204) {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'No Data Returned'
            this.snackTimeout = 5000
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'An error occurred. Try again later'
            this.snackTimeout = 5000
          }
        })
    },
    async updateResource () {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Updating ...'
      this.snackTimeout = -1
      await updateResource.updateResource(this.editResourceObject.id, this.editResourceObject)
        .then((res) => {
          if (res.status === 200) {
            this.snackColor = 'success'
            this.snackText = 'Resource Updated Successfully'
            this.snackTimeout = 3000
            this.editDialog = false
            this.initialize()
          } else {
            this.snackColor = 'error'
            this.snackText = res.data
            this.snackTimeout = 5000
            this.editDialog = false
            this.initialize()
          }
        })
    },
    deleteConfirm (item) {
      this.deleteResourceID = item.uniqueID
      this.deleteConfirmDialog = true
    },
    async deleteItem () {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Deleting...'
      this.snackTimeout = -1
      await deleteResource.deleteResource(this.deleteResourceID)
        .then((response) => {
          if (response.status === 200) {
            this.deleteConfirmDialog = false
            this.initialize()
            this.snackColor = 'success'
            this.snackText = 'Resource was deleted successfully!'
            this.snackTimeout = 3000
          } else {
            this.deleteConfirmDialog = false
            this.snackColor = 'error'
            this.snackText = 'Failed to delete resource.'
            this.snackTimeout = 3000
          }
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e)
        })
    },
    async filterTable () {
      this.loading = true
      const term = this.searchTerm
      const cat = this.searchCat
      let url
      if (this.reportToggle) {
        url = 'articles/resources/admin?count=' + this.rowsPerPage + '&report=' + this.reportToggle
      } else {
        url = 'articles/resources/admin?count=' + this.rowsPerPage
      }
      let extraParam = null
      if (term && cat) {
        extraParam = '&term=' + term + '&cat=' + cat
      } else if (term && !cat) {
        extraParam = '&term=' + term
      } else if (!term && cat) {
        extraParam = '&cat=' + cat
      }
      if (extraParam !== null) {
        url = url + extraParam
      }
      if (this.reportToggle) {
        if (extraParam !== null) {
          this.searchURL = '?count=' + this.rowsPerPage + extraParam + '&report=' + this.reportToggle
        } else if (extraParam === null) {
          this.searchURL = '?count=' + this.rowsPerPage + '&report=' + this.reportToggle
        }
      } else if (!this.reportToggle) {
        if (extraParam !== null) {
          this.searchURL = '?count=' + this.rowsPerPage + extraParam
        } else if (extraParam === null) {
          this.searchURL = '?count=' + this.rowsPerPage
        }
      }
      await resourceManagement.filterTable(url)
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'resources', response.data.results)
            this.$set(this, 'nextCursor', response.data.nextCursor)
            this.$set(this, 'prevCursor', response.data.previousCursor)
            this.loading = false
          } else {
            this.$set(this, 'resources', [])
            this.loading = false
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to retrieve results'
            this.snackTimeout = 5000
          }
        })
    },
    publishResourceConfirm (resource) {
      this.publishResourceID = resource.uniqueID
      this.publishConfirmDialog = true
    },
    async publishResource () {
      const publishID = this.publishResourceID
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Publishing...'
      this.snackTimeout = -1
      await publishResource.publishResource(publishID)
        .then((response) => {
          if (response.status === 200) {
            this.publishConfirmDialog = false
            this.initialize()
            this.snackColor = 'success'
            this.snackText = 'Resource published successfully!'
            this.snackTimeout = 3000
          } else {
            const errorArray = []
            response.data.errors.forEach(element => {
              errorArray.push(element.errorMessage)
            })
            this.publishConfirmDialog = false
            this.initialize()
            this.snackColor = 'error'
            var i
            this.snackText = ''
            for (i = 0; i < errorArray.length; i++) {
              this.snackText += errorArray[i] + ', '
            }
            this.snackTimeout = 5000
          }
        })
    },
    unpublishResourceConfirm (resource) {
      this.unpublishResourceID = resource.uniqueID
      this.unpublishConfirmDialog = true
    },
    async unpublishResource () {
      const unpublishID = this.unpublishResourceID
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Unpublishing...'
      this.snackTimeout = -1
      await publishResource.unpublishResource(unpublishID)
        .then((response) => {
          if (response.status === 200) {
            this.unpublishConfirmDialog = false
            this.initialize()
            this.snackColor = 'success'
            this.snackText = 'Resource unpublished successfully!'
            this.snackTimeout = 3000
          } else {
            this.unpublishConfirmDialog = false
            this.initialize()
            this.snackColor = 'error'
            this.snackText = 'Resource could not be unpublished'
            this.snackTimeout = 5000
          }
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e)
        })
    },
    removeTag (item) {
      const index = this.editResourceObject.tags.findIndex(i => i.tagName === item.tagName)
      if (index >= 0) this.editResourceObject.tags.splice(index, 1)
    },
    removeSuperTag (item) {
      this.editResourceObject.superTag = null
    },
    toggleReport () {
      this.reportToggle = !this.reportToggle
      this.initialize()
    }
  }
}
</script>
