<script>
import axios from 'axios'
import { getInstance } from '../../auth/index'
const authService = getInstance()
export default {
  async updateResource (id, resource) {
    const token = await authService.getTokenSilently()
    const URL = process.env.VUE_APP_APIURL + 'articles/resources/' + id
    var resourceData = {
      name: resource.name,
      summary: resource.summary,
      resourceType: resource.resourceType,
      link: resource.link,
      linkType: resource.linkType,
      imageUrl: resource.imageUrl,
      displayTag: resource.displayTag,
      category: resource.category,
      superTag: resource.superTag,
      tags: resource.tags,
      publishStatus: resource.publishStatus
    }
    const ops = {
      method: 'PUT',
      headers: { 'content-type': 'application/json' },
      data: resourceData,
      url: URL
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  }
}
</script>
