<script>
// import { api } from '@/http-common.js'
import axios from 'axios'
import { getInstance } from '../../auth/index'
const authService = getInstance()
export default {
  async deleteResource (id) {
    const token = await authService.getTokenSilently()
    const url = process.env.VUE_APP_APIURL + 'articles/resources/' + id
    const ops = {
      method: 'DELETE',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  }
}
</script>
