<script>
import axios from 'axios'
import { getInstance } from '../../auth/index'
const authService = getInstance()
export default {
  async publishResource (id) {
    const token = await authService.getTokenSilently()
    const URL = process.env.VUE_APP_APIURL + 'articles/resources/' + id
    var resourceData = {
      publishStatus: true
    }
    const ops = {
      method: 'PUT',
      headers: { 'content-type': 'application/json' },
      data: resourceData,
      url: URL
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async unpublishResource (id) {
    const token = await authService.getTokenSilently()
    const URL = process.env.VUE_APP_APIURL + 'articles/resources/' + id
    var resourceData = {
      publishStatus: false
    }
    const ops = {
      method: 'PUT',
      headers: { 'content-type': 'application/json' },
      data: resourceData,
      url: URL
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  }
}
</script>
